<template lang="pug">
  b-card.organization-profile-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('organization/profile.menus.my_profile') }}
    b-form.form-wc.form-organization-profile-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-profile-founded.mb-4(
              :label="$t('activerecord.attributes.organization/profile.founded')"
              label-for='profile-founded'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#profile-founded.shadow-sm(
                name='founded'
                type='number'
                autocomplete='founded'
                :placeholder="$t('organization/profile.placeholders.founded')"
                :state='formValidationState($v.form.data.attributes.founded)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.founded.$model'
                aria-describedby='profile-founded-feedback'
                trim)
              wc-forms-if#profile-founded-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.founded'
                :remote="formRemoteInvalidFeedback('founded')"
                :validators='{ greater: { number: 1500 }, lessEqual: { number: new Date().getFullYear() } }')

            wc-forms-translations(
              v-for='(locale, key) in $wc.conf.base.locales'
              :key='key'
              :locale='locale'
              :lc='key')
              b-collapse(
                :id='`tl-${key}`'
                :ref='`${key}Tl`'
                :visible="action === 'create' ? true : formShowSection(key)"
                role='tabpanel')
                b-card-body
                  b-form-group(
                    :id='`form-profile-description_${key}`'
                    :label="$t('activerecord.attributes.shared.description') + ' ' + $t(`locales.${key}`)"
                    label-for='`profile-description_${key}`'
                    label-cols-md='2'
                    label-align-md='right')
                    b-form-textarea.shadow-sm(
                      :id='`profile-description_${key}`'
                      name='`description_${key}`'
                      :placeholder="$t('organization/profile.placeholders.description')"
                      :state='formValidationState($v.form.data.attributes[`description_${key}`])'
                      @focus.native='formChange'
                      v-model='$v.form.data.attributes[`description_${key}`].$model'
                      aria-describedby='`profile-description_${key}-feedback`'
                      rows='4'
                      max-rows='8'
                      trim)
                    wc-forms-if(
                      :id='`profile-description_${key}-feedback`'
                      extra-class='ml-feedback'
                      :attribute='$v.form.data.attributes[`description_${key}`]'
                      :remote="formRemoteInvalidFeedback(`description_${key}`)"
                      :validators='{ minLength: {}, maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-profile-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsTranslations from '@components/shared/forms/WcFormsTranslations'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiProfile from '@services/api/organization/profile'
import { Form } from '@common/form'
import { greater, lessEqual } from '@common/form/validations'
import { minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'organization-profile-form',
  mixins: [Form],
  components: {
    WcFormsTranslations,
    WcFormsButtons,
  },
  computed: {
    apiParams() {
      return {
        get: [],
        update: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    let validations = {
      form: {
        data: {
          attributes: {
            founded: {
              greater: greater(1500),
              lessEqual: lessEqual(new Date().getFullYear()),
              remote: () => this.formRemoteValid('founded'),
            },
          },
        },
      },
    }
    for (let locale of this.$wc.conf.base.available_locales) {
      validations.form.data.attributes[`description_${locale}`] = {
        minLength: minLength(this.$wc.conf.limits.min_description),
        maxLength: maxLength(this.$wc.conf.limits.max_description),
        remote: () => this.formRemoteValid(`description_${locale}`),
      }
      validations.form.data.attributes[`${locale}Tl`] = [
        `form.data.attributes.description_${locale}`,
      ]
    }
    return validations
  },
  data() {
    return {
      apiBase: apiProfile,
      apiModel: 'profile',
    }
  },
}
</script>
